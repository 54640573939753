import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Carousel from "../components/Carousel/Carousel"

const HomePage = ({ data }) => {
  const {
    slider_content: slides,
    content_boxes_1,
    content_boxes_2,
    meta_description,
  } = data.wordpressPage.acf

  return (
    <Layout>
      <SEO title="Home" description={meta_description} />
      <div className="container is-fluid is-fullhd">
        <Carousel slides={slides} />
        <div className="no-padding-horizontal home-content-boxes">
          <div className="columns">
            <div className="column is-7 content">
              <div className="text-wrapper">
                <h2
                  className="is-size-2"
                  dangerouslySetInnerHTML={{ __html: content_boxes_1.heading }}
                />
                <p
                  className="lead is-size-4"
                  dangerouslySetInnerHTML={{
                    __html: content_boxes_1.subheading,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: content_boxes_1.content }}
                />
              </div>
            </div>
            <div className="column is-5">
              <Img
                fluid={content_boxes_1.image.localFile.childImageSharp.fluid}
                alt={content_boxes_1.image.alt_text}
              />
            </div>
          </div>
          <div className="columns column-reverse-mobile">
            <div className="column is-5">
              <Img
                fluid={content_boxes_2.image.localFile.childImageSharp.fluid}
                alt={content_boxes_2.image.alt_text}
              />
            </div>
            <div className="column is-7 content">
              <div className="text-wrapper">
                <h2
                  className="is-size-2"
                  dangerouslySetInnerHTML={{ __html: content_boxes_2.heading }}
                />
                <p
                  className="lead is-size-4"
                  dangerouslySetInnerHTML={{
                    __html: content_boxes_2.subheading,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: content_boxes_2.content }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      acf {
        meta_description
        slider_content {
          heading
          subheading
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1344, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        content_boxes_1 {
          heading
          subheading
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 560, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        content_boxes_2 {
          heading
          subheading
          content
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 560, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
