import React from "react"
import Img from "gatsby-image"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import "./Carousel.scss"

const Carousel = ({ slides }) => {
  return (
    <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={125}
      totalSlides={3}
      isIntrinsicHeight
      infinite
      isPlaying
      interval={12000}
    >
      <Slider>
        {slides.map((slide, i) => (
          <Slide index={i} key={`slide-${i}`}>
            <div className="slide-wrapper">
              <Img
                fluid={slide.image.localFile.childImageSharp.fluid}
                alt={slide.image.alt_text}
              />
              <div className="text-wrapper">
                <h3 dangerouslySetInnerHTML={{ __html: slide.heading }} />
                <p dangerouslySetInnerHTML={{ __html: slide.subheading }} />
              </div>
            </div>
          </Slide>
        ))}
      </Slider>
    </CarouselProvider>
  )
}

export default Carousel
